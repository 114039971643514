<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div
            class="nav"
            v-for="(item, index) in navarr[0][num]"
            :key="index"
            @click="router(index)"
          >
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>
        
        <div class="main_right">
          
          <div class="manager_box">
            <div class="img_header">
              <img :src="encnimg[num].imgurl" alt="" />
            </div>
            <div class="fs30">{{manager[num].title}}</div>
            <div class="manager_text">
              {{manager[num].content1}}
            </div>
            <div class="manager_text">
             {{manager[num].content2}}
            </div>
            <div class="manager_text">
              {{manager[num].content3}}
            </div>

            <div class="fs30">{{manager[num].title2}}</div>
            <div class="imgBox">
              <div class="ma010">
                <img src="../img/BAANSAITARA.png" alt="" />
              </div>
              <div class="allocationA">
                <div>
                  <img src="../img/OZENCHAWENGNOIPROJECT.png" alt="" />
                </div>
                <div>
                  <img src="../img/SAITARAPEAK.png" alt="" />
                </div>
              </div>
              <div class="allocationB">
                <div>
                  <img src="../img/ALICEAUVILLA.png" alt="" />
                </div>
                <div>
                  <img src="../img/BLUESKYVILLA.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Herder.vue"; 
import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  components: { Header, Footer },
  data() {
    return {
      encnimg:[
        {
          imgurl:require('../img/headerimg.png')
        },
        {
          imgurl:require('../img/headerenimg.png')
        }
      ],
      navarr: [
        {
          0: ["开发商介绍"],

          1: ["About Developer"],
        },
      ],
      manager:{
        0:{
          content1:`WING
              PROPERTY成立于1998年，是一家拥有25年开发经验的泰国本土知名开发商，承担过多个泰国中大型项目的开发和施工。业务遍及在万伦，素叻他尼，苏梅岛等地，目前完工项目20余个，凭借良好的声誉和高质量的项目交付，成为泰国顶级老牌开发商。WING
              PROPERTY董事长于2015年被任命为苏梅岛副市长。`,
          content2:`WING
              PROPERTY致力于维持自然环境的同时，不断提高居民的生活质量，发展更好的生活。本着“为更好的生活而发展”的指导原则，我们的住宅以优越的地理位置、精心设计全面满足客户需求。为投资者和客户提供良好租金回报的租赁管理。`,
          content3:'',
          title:'关于我们',
          title2:'成功案例'
        },
        1:{
          content1:`Wing Property is a leading full-scale real estate developer in Thailand, and it has built its reputation since 1998 with an impressive portfolio of over 20 completed projects ranging from residential and hospitality developments to the government projects throughout Surat Thani`,
          content2:`With a good reputation and high quality projects delivered, the chairman of Wing Property Ltd.had been appointed as Deputy Mayor of Koh Samui in 2015. To look beyond mere personal success,Wing Property strives to continuously improve the quality of life of our residents with our commitment to develop for better living and sustain the beautiful nature surrounding`,
          content3:`With our guiding principle of “Developing for better living” , our residence encompasses the key ingredients of prime location, meticulously designed,  well-made homes, comprehensive services to fully attend to the needs of our customers, rental management to provide a good rental return for our investor-buyers. We endeavour to innovate and develop our projects that is outstanding, high quality and environmentalfriendly, to deliver satisfaction to all customers and communities`,
          title:'About Us',
          title2:''
        }
      }
    };
  },
};
</script>

<style lang="less" scoped>
.allocationA {
  display: flex;
}
.ma010 {
  height: 3.45rem;
  margin-bottom: 0.1rem;
}
.ma010 img {
  width: 100%;
  height: 100%;
}
.imgBox p {
  text-align: center;
  color: white;
  font-size: 0.16rem;
  position: relative;
  bottom: 0.20rem;
}
.allocationB {
  display: flex;
}

.allocationB :nth-child(2) img {
  width: 100%;
}
.allocationB :nth-child(1) {
    width: 100%;
  flex: 1;
  height: 2.53rem;
  margin-right: 0.1rem;
}

.allocationA :nth-child(1) {
  width: 3.25rem;
  height: 2.53rem;
  margin-right: 0.1rem;
}
.allocationA :nth-child(2) {
  height: 2.53rem;
  flex: 1;
}
.allocationA :nth-child(2) img {
  width: 100%;
}
.imgBox {
  width: 8rem;
  margin: 0 auto;
}
.allocationA,
.allocationB {
  margin-bottom: 0.1rem;
}
.manager_text {
  margin: 0.4rem auto;
  font-size: 0.17rem;
  line-height: 0.3rem;
  color: #666666;
  margin-bottom: 0.3rem;
}
.mating_img {
  img {
    width: 100%;
  }
}
.none {
  border: none !important;
}
.mat {
  margin: 0.3rem auto;
  border-bottom: 0.02rem solid #dad5ce;
}
.row {
  display: flex;
  width: 8rem;

  margin: 0.3rem auto;
}
.dis {
  width: 50%;
  display: flex;
  img {
    height: 0.3rem;
  }
  div {
    font-size: 0.17rem;
    line-height: 0.3rem;
    margin-left: 0.1rem;
  }
}
.manager_box {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.mating_img {
  img {
    width: 100%;
  }
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.fs30 {
  margin: 0.8rem auto;
  font-size: 0.3rem;
  text-align: center;
}
.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}
.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}
.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}
.nav:hover {
  background-color: #a08e75;
  color: white;
}
.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}
.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}
.main_right {
  flex: 1;
}
</style>